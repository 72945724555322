import Translator from "bazinga-translator";

window.Translator = Translator;

let ready = function (fn) {
  if (document.readyState !== "loading") {
    fn();
  } else {
    document.addEventListener("DOMContentLoaded", fn);
  }
};

let setBrowserInfos = function () {
  let date = new Date();
  let offset = date.getTimezoneOffset();

  let browserInfos = JSON.parse(
    '{"screenWidth":' +
      screen.width +
      ',"screenHeight":' +
      screen.height +
      ',"timezone":' +
      offset +
      ',"javaEnabled":' +
      navigator.javaEnabled() +
      ',"screenColorDepth":' +
      screen.colorDepth +
      ',"language":"' +
      navigator.language +
      '"}'
  );

  // Cookie browserInfos for 3DS2 purpose
  document.cookie =
    "browserInfos=" + JSON.stringify(browserInfos) + " ;Secure; SameSite=Lax";
};

let init = function () {
  setBrowserInfos();
  window.validateEmail = function validateEmail(email) {
    let re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };
  if (typeof document.getElementById("marketing-marketing") !== "undefined") {
    document
      .getElementById("confirm-marketing")
      .addEventListener("click", function () {
        let email = document.getElementById("marketing-email").value;
        if (validateEmail(email)) {
          document.getElementsByClassName("marketing-title")[0].style.display =
            "none";
          document.getElementsByClassName("marketing-form")[0].style.display =
            "none";
          document.getElementsByClassName(
            "marketing-caption"
          )[0].style.display = "none";
          document.getElementsByClassName(
            "marketing-confirmed"
          )[0].style.display = "block";
        } else {
          alert(Translator.trans("enterEmail"));
        }
      });
  }
};

ready(init);
